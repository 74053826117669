@font-face {
    font-family: 'lora';
    src: url('./Lora-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserrat';
    src: url('./Montserrat-Thin.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'raleway';
    src: url('./Raleway-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calgary';
    src: url('./Calgary_DEMO.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}